import { colors } from "../constants/colors";
import { FaBrain, FaHouseUser, FaBalanceScaleLeft, FaBullseye } from 'react-icons/fa'

const Mission = () => {
    return (
      <div className='flex flex-col gap-4 py-3'>
        <div className="flex flex-col md:flex-row justify-left items-left md:justify-center md:gap-6 mx-4 md:mx-[6vw] bg-[#fff] py-8 rounded-2xl md:py-16" data-aos="fade-right" data-aos-delay="0">
            <div className="flex flex-col md:justify-center md:w-[30vw] md:gap-8">
                <div className="mx-7 text-md font-medium">
                    <h1 className={`text-4xl font-bold flex flex-col text-[${colors.blue}] mb-4 md:text-6xl md:mb-10`} >
                        Vision
                    </h1>
                    <div className="flex flex-col gap-4">
                    <p className="text-[#9E9E9E] text-justify md:text-lg">At Prime Realty Group, we envision reshaping the real estate landscape by embodying honesty and accessibility at every turn. Our aspiration is to stand as a beacon of professionalism and transparency in the industry, instilling confidence in our clients. We provide innovative and creative solutions that break down traditional barriers, ensuring an easy way towards meeting our clients' needs, where they feel secure and informed every step of the way. </p>
                    </div>
                </div>
                <div className="flex flex-row gap-6 md:gap-16 justify-center items-center mt-6 text-[10px] font-medium text-center">
                    <div className="flex flex-col gap-2 text-center justify-center items-center md:text-sm" data-aos="fade-up" data-aos-delay="100">
                        <div className={`flex justify-center items-center bg-[${colors.blue}] rounded-full w-[30px] h-[30px] md:w-[60px] md:h-[60px] `}>
                            <FaBalanceScaleLeft fill="#fff" className="text-[20px] md:text-[30px]" />
                        </div>
                        <span>Honesty</span>
                    </div>
                    <div className="flex flex-col gap-2 text-center justify-center items-center md:text-sm" data-aos="fade-up" data-aos-delay="200">
                        <div className={`flex justify-center items-center bg-[${colors.blue}] rounded-full w-[30px] h-[30px] md:w-[60px] md:h-[60px] `}>
                            <FaBrain fill="#fff" className="text-[20px] md:text-[30px]" />

                        </div>
                        <span>Smart Solutions</span>
                    </div>
                    <div className="flex flex-col gap-2 text-center justify-center items-center md:text-sm" data-aos="fade-up" data-aos-delay="300">
                        <div className={`flex justify-center items-center bg-[${colors.blue}] rounded-full w-[30px] h-[30px] md:w-[60px] md:h-[60px] `}>
                            <FaHouseUser fill="#fff" className="text-[20px] md:text-[30px]" />

                        </div>
                        <span>Growth</span>
                    </div>
                    <div className="flex flex-col gap-2 text-center justify-center items-center md:text-sm" data-aos="fade-up" data-aos-delay="300">
                        <div className={`flex justify-center items-center bg-[${colors.blue}] rounded-full w-[30px] h-[30px] md:w-[60px] md:h-[60px] `}>
                            <FaBullseye fill="#fff" className="text-[20px] md:text-[30px]" />

                        </div>
                        <span>Visionary</span>
                    </div>
                </div>
            </div>
            <div className="my-6 mx-6">
                <img className="rounded-2xl md:max-w-[300px]" src="https://images.unsplash.com/photo-1532323544230-7191fd51bc1b?q=80&w=3387&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Home-4" srcset="" style={{borderTopRightRadius: '200px', borderTopLeftRadius: '200px'}}/>
            </div>
        </div>
    </div>
  )
}

export default Mission;