import { colors } from "../constants/colors";
import { GoShieldCheck } from "react-icons/go";

const Header = () => {
  return (
    <div className="flex flex-col md:flex-row gap-4 md:gap-8 pb-3 md:w-auto md:mx-[6vw]">
      <div
        className="flex flex-col justify-left items-left md:justify-center mx-4 md:mx-0 md:w-1/2 bg-[#fff] py-8 rounded-2xl"
        data-aos="fade-up"
      >
        <div className="mx-7 md:mx-10 text-md md:text-lg font-medium">
          <h1 className="text-4xl md:text-6xl font-bold flex flex-col gap-2 md:gap-3">
            <span className="">A reliable way to</span>
            <span className="text-[#003AFF]">sell your home</span>
          </h1>
          <div className="bg-[#f5f5fc] rounded-xl px-8 py-8 my-6">
            <div className="flex flex-col gap-4 md:gap-4">
              <div className="flex flex-col md:flex-row gap-4">
                <div className="flex justify-between w-full p-2 md:p-4 pl-3 text-sm md:pl-3 bg-[#fff] rounded-xl">
                  <input
                    className="text-[13px] md:text-[16px] w-full"
                    type="text"
                    placeholder="Your Name"
                  />
                </div>
                <div className="flex justify-between w-full p-2 md:p-4 pl-3 text-sm md:pl-3 bg-[#fff] rounded-xl">
                  <input
                    className="text-[13px] md:text-[16px] w-full"
                    type="text"
                    placeholder="Your Email"
                  />
                </div>
                <div className="flex justify-between w-full p-2 md:p-4 pl-3 text-sm md:pl-3 bg-[#fff] rounded-xl">
                  <input
                    className="text-[13px] md:text-[16px] w-full"
                    type="text"
                    placeholder="Your Phone"
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-4">
                <div className="flex justify-between w-full p-2 md:p-4 pl-3 text-sm md:pl-3 bg-[#fff] rounded-xl">
                  <input
                    className="text-[13px] md:text-[16px] w-full"
                    type="text"
                    placeholder="City"
                  />
                </div>
                <div className="flex justify-between w-full p-2 md:p-4 pl-3 text-sm md:pl-3 bg-[#fff] rounded-xl">
                  <input
                    className="text-[13px] md:text-[16px] w-full"
                    type="text"
                    placeholder="State"
                  />
                </div>
                <div className="flex justify-between w-full p-2 md:p-4 pl-3 text-sm md:pl-3 bg-[#fff] rounded-xl">
                  <input
                    className="text-[13px] md:text-[16px] w-full"
                    type="text"
                    placeholder="Zip Code"
                  />
                </div>
              </div>
              <div className="flex justify-between w-full p-2 md:p-4 pl-5 md:pl-3 bg-[#fff] rounded-xl">
                <input
                  className="text-[13px] md:text-[16px] w-full"
                  type="text"
                  placeholder="Property Address"
                />
              </div>
              <div
                className={`text-white bg-[${colors.blue}] text-center p-4 px-5 md:px-7 rounded-xl text-[12px] md:text-[15px] hover:bg-[#3c3c3c] md:cursor-pointer transition-colors	`}
              >
                Send me the information
              </div>
            </div>
          </div>
          <div className="flex flex-col mx-2 gap-4 max-w-[70vw] md:text-lg">
            <p
              className="text-[#3c3c3c]"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              Your Home, Our Promise: Instant Buyers, Lasting Confidence.
            </p>
            <div
              className="text-[#3c3c3c] flex flex-wrap gap-2 text-md justify-start items-center"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="flex flex-row justify-center items-center gap-2">
                <GoShieldCheck className="text-[#003aff]" />
                <p className="text-[#3c3c3c] flex">Easy process</p>
              </div>
              <div className="flex flex-row justify-center items-center gap-2">
                <GoShieldCheck className="text-[#003aff]" />
                <p className="text-[#3c3c3c] flex">No commissions</p>
              </div>
              <div className="flex flex-row justify-center items-center gap-2">
                <GoShieldCheck className="text-[#003aff]" />
                <p className="text-[#3c3c3c] flex">transparency</p>
              </div>
              <div className="flex flex-row justify-center items-center gap-2">
                <GoShieldCheck className="text-[#003aff]" />
                <p className="text-[#3c3c3c] flex">no repairs needed</p>
              </div>
            </div>
            <p
              className="text-[#9E9E9E]"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              Check our{" "}
              <span className="text-[#003aff]">Mission and Vision &gt;</span>
            </p>
            <p
              className="text-[#9E9E9E]"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              And give us the opportunity to convert your dreams into reality.
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 md:gap-8 md:w-1/2 md:justify-center md:items-center">
        <div className="mx-4 md:mx-0 rounded-2xl">
          <img
            className="rounded-2xl"
            src="https://images.unsplash.com/photo-1512917774080-9991f1c4c750?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="Home-1"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
        <div className="flex flex-row gap-4 md:gap-8 mx-4 md:mx-0 rounded-2xl ">
          <div className="w-full flex">
            <img
              className="rounded-2xl w-full h-auto"
              src="https://images.unsplash.com/photo-1570129477492-45c003edd2be?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="Home-2"
              data-aos="fade-up"
              data-aos-delay="600"
            />
          </div>
          <div className="w-full flex md:mr-0">
            <img
              className="rounded-2xl w-full h-auto"
              src="https://images.unsplash.com/photo-1583608205776-bfd35f0d9f83?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="Home-3"
              data-aos="fade-up"
              data-aos-delay="700"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
