import { colors } from "../constants/colors";

const Footer = () => {
  return (
    <div className="flex flex-col text-center font-bold py-8">
      <div className="flex flex-col gap-2">
        <div className="text-lg">Are you interested in our project?</div>
        <div
          className={`text-[${colors.blue}] cursor-pointer text-lg md:mb-10 md:text-2xl`}
        >
          Contact Us
        </div>
        <div className={`bg-[${colors.blue}] rounded-2xl mx-4 md:mx-[6vw] md:py-6`} data-aos="fade-down" data-aos-delay="0">
          <div className="flex justify-center items-center gap-2 h-[60px] text-center font-bold text-md md:text-2xl text-white md:mb-4">
            Prime Realty Group
            <div className="rounded-full bg-white h-[16px] w-[16px]"></div>
          </div>
          <div className="flex justify-between mb-6 p-2 md:p-3 md:pl-8 pl-5 bg-[#fff] rounded-full shadow-[0px_0px_10px_0px_rgba(0,0,0,0.25)] mx-4 md:mx-16">
            <input
              className="text-[13px] md:text-[16px] w-full"
              type="text"
              placeholder="yourmail@mail.com"
            />
            <div
              className={`text-white bg-[${colors.blue}] p-1 md:p-2 px-5 md:px-7 rounded-full text-[12px] md:text-[14px] hover:bg-[#3c3c3c] md:cursor-pointer transition-colors`}
            >
              Send
            </div>
          </div>
        </div>
        <div className="mt-6 text-md flex flex-row gap-6 font-medium justify-center">
          <div className={`text-[${colors.dark_gray}] hover:text-[#000] cursor-pointer`}>
            Buy a Home
          </div>
          <div className={`text-[${colors.dark_gray}] hover:text-[#000] cursor-pointer`}>
            Sell My Home
          </div>
          <div className={`text-[${colors.dark_gray}] hover:text-[#000] cursor-pointer`}>
            Rent a House
          </div>
        </div>
        <div className="text-xs md:text-md font-medium mx-4 mt-3 text-[#9E9E9E]">
            Copyright ©2023 Prime Realty Group - All Rights Reserved
        </div>
      </div>
    </div>
  );
};

export default Footer;
