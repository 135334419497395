import { colors } from "../constants/colors";
import { FaEye, FaDoorOpen, FaHandshake, FaVoteYea } from 'react-icons/fa'


const Mission = () => {
    return (
      <div className='flex flex-col gap-4 py-3'>
        <div className="flex flex-col md:flex-row justify-left items-left md:justify-center md:gap-6 mx-4 md:mx-[6vw] bg-[#fff] py-8 rounded-2xl" data-aos="fade-left" data-aos-delay="0">
            <div className="flex flex-col md:justify-center md:w-[30vw] md:gap-8">
                <div className="mx-7 text-md font-medium">
                    <h1 className={`text-4xl font-bold flex flex-col text-[${colors.blue}] mb-4 md:text-6xl md:mb-10`} >
                        Mission
                    </h1>
                    <div className="flex flex-col gap-4">
                    <p className="text-[#9E9E9E] text-justify md:text-lg">At Prime Realty Group, our commitment is to instill confidence in every step of the home buying and selling process. We strive to create a trustworthy environment by swiftly turning dreams into reality. By removing traditional barriers to homeownership, we offer a transparent and fair approach that empowers homeowners to sell their homes promptly, avoiding unnecessary fees and ensuring a seamless process with reliable results. </p>
                    </div>
                </div>
                <div className="flex flex-row gap-6 md:gap-16 justify-center items-center mt-6 text-[10px] font-medium text-center">
                    <div className="flex flex-col gap-2 text-center justify-center items-center md:text-sm" data-aos="fade-up" data-aos-delay="100">
                        <div className={`flex justify-center items-center bg-[${colors.blue}] rounded-full w-[30px] h-[30px] md:w-[60px] md:h-[60px] `} >
                            <FaEye fill="#fff" className="text-[20px] md:text-[30px]" />
                        </div>
                        <span>Confidence</span>
                    </div>
                    <div className="flex flex-col gap-2 text-center justify-center items-center md:text-sm" data-aos="fade-up" data-aos-delay="200">
                        <div className={`flex justify-center items-center bg-[${colors.blue}] rounded-full w-[30px] h-[30px] md:w-[60px] md:h-[60px] `} >
                            <FaDoorOpen fill="#fff" className="text-[20px] md:text-[30px]" />

                        </div>
                        <span>Simplicity</span>
                    </div>
                    <div className="flex flex-col gap-2 text-center justify-center items-center md:text-sm" data-aos="fade-up" data-aos-delay="300">
                        <div className={`flex justify-center items-center bg-[${colors.blue}] rounded-full w-[30px] h-[30px] md:w-[60px] md:h-[60px] `} >
                            <FaHandshake fill="#fff" className="text-[20px] md:text-[30px]" />

                        </div>
                        <span>Loyalty</span>
                    </div>
                    <div className="flex flex-col gap-2 text-center justify-center items-center md:text-sm" data-aos="fade-up" data-aos-delay="300">
                        <div className={`flex justify-center items-center bg-[${colors.blue}] rounded-full w-[30px] h-[30px] md:w-[60px] md:h-[60px] `} >
                            <FaVoteYea fill="#fff" className="text-[20px] md:text-[30px]" />

                        </div>
                        <span>Transparency</span>
                    </div>
                </div>
            </div>
            <div className="my-6 mx-6">
                <img className="rounded-2xl md:max-w-[300px]" src="https://images.unsplash.com/photo-1522156373667-4c7234bbd804?q=80&w=3300&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Home-4" srcset="" style={{borderTopRightRadius: '200px', borderTopLeftRadius: '200px'}}/>
            </div>
        </div>
    </div>
  )
}

export default Mission;