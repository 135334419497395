import { colors } from "../constants/colors";

const Team = () => {
  return (
    <div className="flex flex-col gap-4 py-3 md:mx-[6vw]">
      <div className="flex flex-col justify-left items-left mx-4 md:mx-0 bg-[#fff] py-8 md:py-16 rounded-2xl" data-aos="fade-down" data-aos-delay="0">
        <div className="mx-7 text-md font-medium mb-4">
          <h1 className={`text-4xl md:text-6xl font-bold text-[${colors.blue}] mb-4 md:mb-16 md:text-center `}>
            <span className="text-black">This is</span>
            {" us"}
          </h1>
        </div>
        <div className="flex flex-col md:flex-row justify-center items-center gap-12 md:text-lg">
            <div className="flex flex-col justify-center items-center text-center mx-8 gap-4 font-bold" data-aos="fade-up" data-aos-delay="100">
                <div className={`w-[200px] h-[200px] bg-cover bg-center rounded-full bg-[url('https://img.freepik.com/premium-photo/young-businessman-outdoor-modern-setting_53419-11374.jpg')]`}>
                </div>
                <div className="flex flex-col">
                    <span className={`text-md text-[${colors.blue}]`}>CFO</span>
                    <span className="text-md">Johendry Colmenares</span>
                </div>
            </div>
            <div className="flex flex-col justify-center items-center text-center mx-8 gap-4 font-bold" data-aos="fade-up" data-aos-delay="200">
                <div className={`w-[200px] h-[200px] bg-cover bg-center rounded-full bg-[url('https://img.freepik.com/premium-photo/young-businessman-outdoor-modern-setting_53419-11374.jpg')]`}>
                </div>
                <div className="flex flex-col">
                    <span className={`text-md text-[${colors.blue}]`}>CEO</span>
                    <span className="text-md">Diego Rincon</span>
                </div>
            </div>
            <div className="flex flex-col justify-center items-center text-center mx-8 gap-4 font-bold" data-aos="fade-up" data-aos-delay="300">
                <div className={`w-[200px] h-[200px] bg-cover bg-center rounded-full bg-[url('https://img.freepik.com/premium-photo/young-businessman-outdoor-modern-setting_53419-11374.jpg')]`}>
                </div>
                <div className="flex flex-col">
                    <span className={`text-md text-[${colors.blue}]`}>COO</span>
                    <span className="text-md">Daniel Pereira</span>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
