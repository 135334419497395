import React, { useEffect } from 'react';
import './App.css';
import Header from './components/Header';
import Navbar from './components/Navbar';
import Mission from './components/Mission';
import Vision from './components/Vision';
import Team from './components/Team';
import Footer from './components/Footer';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function App() {
  useEffect(() => {
    document.title = 'Prime Realty Group';
  }, []);

  return (
    <div className='flex flex-col md:gap-4'>
      <Navbar />
      <Header />
      <Mission />
      <Vision />
      <Team />
      <Footer />
    </div>
  );
}

export default App;
