import logo from "../assets/logo.png";

const Navbar = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 items-center justify-center text-center font-bold text-md md:text-xl py-2 my-2 mx-10">
      <div></div>
      <div className="flex justify-self-center gap-2">
        <img alt="logo" src={logo} className="h-[120px]" />
      </div>
      <div className="flex justify-center md:justify-end">
        <a
          className="bg-[#003AFF] py-2 px-5 rounded-full text-white font-medium md:text-sm md:w-1/2"
          href="tel:+13058334842"
        >
          Call Us +1 (305) 833-4842
        </a>
      </div>
    </div>
  );
};

export default Navbar;
